<template>
    <a-card>
        <a-page-header
            title="橙子建站程序化落地页管理"
        />

        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="落地页组">
                        <a-input
                            v-model="form.groupTitle"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                            @change="onChangePrincipal"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="集客场景">
                        <a-select
                            mode="multiple"
                            v-model="form.customerSceneList"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in customerSceneList"
                                :key="item.code"
                            >
                                {{ item.desc }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="车系">
                        <a-select
                            mode="multiple"
                            v-model="form.carSeriesIdList"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="落地页组状态">
                        <a-select
                            v-model="form.adGroupStatus"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option key="LANDING_GROUP_STATUS_AVAILABLE">可用</a-select-option>
                            <a-select-option key="LANDING_GROUP_STATUS_UNAVAILABLE">不可用</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="转化率">
                        <a-input-number :min="0" v-model="form.convertRateMin"></a-input-number>
                        ~
                        <a-input-number :min="0" v-model="form.convertRateMax"></a-input-number>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                        <a-button type="primary" @click="jumpDetailPage(0)">新建程序化落地页</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :pagination='false'
            class="mt-20"
            @change="handleTableChange"
        >
            <div
                slot="operation"
                slot-scope="text, record"
            >
                <a-button type="link" @click="jumpBindPage(record)">绑定</a-button>
                <a-button type="link" @click="jumpDetailPage(record.id)">修改</a-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />

        <preview-modal
            :visible="previewVisible"
            :preview-src="previewSrc"
            width="50%"
            @cancel="handlePreviewCancel"
        />

        <div class="mt-20">
            <div>PS：</div>
            <div>1、转化率数据为凌晨时绑定的落地页计算所得</div>
            <div>2、每天凌晨系统会新建满足条件的程序化落地页和为未满10个落地页的程序化落地页添加满足条件的落地页(优先绑定新建的落地页)</div>
        </div>
    </a-card>
</template>

<script>
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo";

export default {
    components: {
        previewModal
    },
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {},
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '落地页组状态',
                    customRender:(text, row) => {
                        let txt = <div class="red">不可用</div>
                        return row.adGroupStatus == 'LANDING_GROUP_STATUS_AVAILABLE' ? '可用' : txt
                    }
                },
                {
                    align: 'center',
                    title: '落地页组',
                    dataIndex: 'groupTitle',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '流量分配方式',
                    dataIndex: 'adGroupFlowType',
                    customRender:(text, row) => {
                        return row.adGroupFlowType == 'FLOW_DISTRIBUTION_TYPE_INTELLIGENCE' ? '智能分配' : row.adGroupFlowType == 'FLOW_DISTRIBUTION_TYPE_AVERAGE' ? '平均分配' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '车系',
                    dataIndex: 'carSeriesName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '集客场景',
                    dataIndex: 'customerSceneDesc',
                    customRender(text) {
                        return text ||'-'
                    }
                },
                {
                    align: 'center',
                    title: '程序化落地页适用范围',
                    dataIndex: 'groupScope',
                    customRender: (text, row) => {
                        return row.groupScope == 1 ? '车系' : row.groupScope == 2 ? '品牌' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '已绑定落地页数',
                    dataIndex: 'totalPlay',
                    customRender: (text, row) => {
                        return (row.landingPageCount ?? '') !== '' ? row.landingPageCount : '-'
                    }
                },
                {
                    align: 'center',
                    title: '转化率',
                    dataIndex: 'convertRate',
                    sorter: true,
                    customRender: (text, row) => {
                        return (row.convertRate ?? '') !== '' ? `${(row.convertRate).toFixed(2)}%` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    customRender:(text, row) => {
                        return <div style="text-align: left;">
                                <div>{ row.advertiserName || '-' }</div>
                                <div class="txt">平台账套名：{row.adPlatformAccountName || '-'}</div>
                            </div>
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            principalList: [],
            seriesList: [],
            customerSceneList: [],
            previewVisible: false,
            previewSrc: '',
        }
    },
    created() {
        this.getPrincipalList()
        this.getPrincipalSeriesList()
        this.getCustomerScene()
        this.getDataList()
    },
    methods: {
        handlePreviewOpen(url) {
            this.previewVisible = true
            this.previewSrc = url
        },
        handlePreviewCancel() {
            this.previewVisible = false
            this.previewSrc = ''
        },
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangePrincipal(id) {
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: id,
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
            this.seriesList = []
            this.getPrincipalSeriesList()
        },
        handleTableChange(pagination, filters, sorter) {
            this.getDataList({
                convertRateSort: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : ''
            });
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList(data={}) {
            this.loading = true
            const _form = this.form
            let params = {
                ..._form,
                ...data,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.materialManage.getAdLandingPageGroupList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpBindPage(record) {
            this.$router.push({
                path: `/programmaticLandingPage/bindList`,
                query: {
                    id: record.id,
                    name: record.groupTitle,
                    adPlatformAccountId: record.adPlatformAccountId
                }
            })
        },
        jumpDetailPage(id) {
            this.$router.push({
                path: `/programmaticLandingPage/detail`,
                query: {
                    id,
                }
            })
        },
        onBatch() {
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.mt-20 {
    margin-top: 20px;
}
.img {
    width: 60px;
}
.txt {
    color: #ccc;
}
</style>
